const { tilbyVersion } = require('app/tilby.properties.json');

//Import datadog as first library, as we need to patch console API for logging ASAP
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
    clientToken: 'pubc6b70de870d3c69eb7d43691100b6dab',
    forwardConsoleLogs: 'all',
    site: 'datadoghq.eu',
    service: 'tilby-app',
    sampleRate: 100,
    version: tilbyVersion
});

datadogLogs.logger.setHandler('console');

//Polyfills
import 'core-js/actual';

import "./zone-flag";
import "zone.js";
import "reflect-metadata";
